import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-basic-three-grid',
  templateUrl: './basic-three-grid.component.html',
  styleUrls: ['./basic-three-grid.component.scss']
})
export class BasicThreeGridComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
