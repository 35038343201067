<!-- section start -->
<section class="portfolio-creative portfolio-section p-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6  p-0 isotopeSelector">
                <img src="assets/images/portfolio/1.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-md-6  p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">
                        Lorem Ipsum
                    </h2>
                    <p class="head-sub-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6  p-0 isotopeSelector order-md-2">
                <img src="assets/images/portfolio/2.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-md-6  p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">
                        Lorem Ipsum
                    </h2>
                    <p class="head-sub-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6  p-0 isotopeSelector">
                <img src="assets/images/portfolio/3.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-md-6  p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">
                        Lorem Ipsum
                    </h2>
                    <p class="head-sub-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6  p-0 isotopeSelector order-md-2">
                <img src="assets/images/portfolio/4.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-md-6  p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">
                        Lorem Ipsum
                    </h2>
                    <p class="head-sub-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6  p-0 isotopeSelector">
                <img src="assets/images/portfolio/5.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-md-6  p-0 h-100 my-auto">
                <div class="portfolio-text m-auto text-center">
                    <h2 class="head-text">
                        Lorem Ipsum
                    </h2>
                    <p class="head-sub-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->
