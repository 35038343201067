<!--copyright start-->
<footer class="resume copyright copyright-bg">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="link link-horizontal">
                  <ul class="justify-content-center">
                      <li><a style="color:#DAB0B0">Adobe Suite</a></li>
                      <li><a style="color:#DAB0B0">Discord Management</a></li>
                      <li><a style="color:#DAB0B0">SEO</a></li>
                      <li><a style="color:#DAB0B0">Content Creation</a></li>
                      <li><a style="color:#DAB0B0">Customer Acquistion</a></li>
                      <li><a style="color:#DAB0B0">Data Analytics</a></li>
                  </ul>
              </div>
              <hr>
          </div>
          <div class="col-12">
              <div class="text-center">
                  <div class="social-link link-horizontal">
                      <ul class="justify-content-center">
                          <li><a href="https://facebook.com/101043908249189/" class="copyright-text"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                          <li><a href="https://instagram.com/arianna_monique/" class="copyright-text"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                          <li><a href="https://youtube.com/channel/UCoQribXfGQ0g2WC16iSqDDA/" class="copyright-text"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                          <li><a href="https://www.linkedin.com/in/arianna-castillo-mba-b84136b7/" class="copyright-text"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                          <a href="https://www.tiktok.com/@ari_monique/"><img style="color:black; width: 18px" src="assets/images/resume/tiktok.svg"></a>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-12">
              <div class="m-l-auto center-para">
                  <h6 class="copyright-text text-center">© Created by Arianna Monique using Angular and Azure Cloud Services</h6>
              </div>
          </div>
      </div>
  </div>
</footer>
<!--copyright end-->
<app-tap-to-top></app-tap-to-top>