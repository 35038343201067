<!--footer start-->
<footer class="app2 bg footer2 p-0" [ngStyle]="{'background-image': 'url(assets/images/app_landing2/l-2-footer.png)', 'background-repeat': 'no-repeat', 'background-position': 'top'}">
        <div class="subscribe subscribe-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div class="subscribe">
                            <div class="center-content">
                                <div class="form-group m-0">
                                    <div class="flex">
                                        <i aria-hidden="true" class="fa fa-envelope icon"></i>
                                        <input class="form-control text-capitalize" name="email"
                                               placeholder="example@gmail.com" type="email">
                                        <div class="button-primary">
                                            <button class=" btn btn-default btn-gradient text-white text-capitalize"
                                                    type="submit">
                                                subscribe now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="logo-sec">
                            <div class="footer-title mobile-title p-t-0">
                                <h3 class="text-white">About Us</h3>
                            </div>
                            <div class="footer-contant">
                                <img alt="" class="img-fluid footer-logo" src="assets/images/logo/1.png">
                                <div class="footer-para">
                                    <p class="text-white">Lorem ipsum dolor sit consectetur adipisicing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim lorem
                                        sed do </p>
                                </div>
                                <ul class="d-flex footer-social social">
                                    <li class="footer-social-list">
                                        <a href="javascript:void(0)"><i aria-hidden="true" class="fa fa-facebook"></i></a>
                                    </li>
                                    <li class="footer-social-list">
                                        <a href="javascript:void(0)"><i aria-hidden="true" class="fa fa-twitter"></i></a>
                                    </li>
                                    <li class="footer-social-list">
                                        <a href="javascript:void(0)"><i aria-hidden="true" class="fa fa-google"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-title mobile-title">
                            <h3 class="text-white">Post Tags</h3>
                        </div>
                        <div class="footer-contant">
                            <h5 class="footer-headings">Post Tags</h5>
                            <div class="link-btns">
                                <ul>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">app</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">business</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">corporation</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">creative</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">design</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">fashion</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">food</a>
                                    </li>
                                    <li class="buttons">
                                        <a href="javascript:void(0)">mobile</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="footer-title mobile-title">
                            <h3 class="text-white">blog categories</h3>
                        </div>
                        <div class="footer-contant">
                            <h5 class="footer-headings">Blog Categories</h5>
                            <div>
                                <ul class="footer-lists">
                                    <li>
                                        <a href="javascript:void(0)">App Review</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Audio Post</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Default Post</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Uncategorized</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Video Post</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="footer-title mobile-title">
                            <h3 class="text-white">Contact Info</h3>
                        </div>
                        <div class="footer-contant">
                            <h5 class="footer-headings">Contact Info</h5>
                            <div>
                                <ul class="footer-lists contacts">
                                    <li>
                                        <i aria-hidden="true" class="fa fa-map-marker"></i>
                                        Lorem ipsum dolor sit consectetur adipisicing elit, sed do eiusmod
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="fa fa-phone m-r-15"></i>(+066) 518 - 457 - 5181
                                    </li>
                                    <li><i aria-hidden="true" class="fa fa-envelope m-r-15"></i>contact@gmail.com
                                    </li>
    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </footer>
    <!--footer end-->
    