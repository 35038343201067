<!-- header Start-->
<section class="event header" id="homer">
    <div class="header3 bg" [ngStyle]="{'background-image': 'url(assets/images/event/event_slider.jpg)'}">
        <div class="event-content">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1">
                        <div class="center-text">
                            <div class="text-center w-100">
                                <div class="h1-margin">
                                    <h1>come <span>unice</span></h1>
                                </div>
                                <div class="wedding-time">
                                    <div class="row">
                                        <div class="col-sm-3 col-6">
                                            <div class="event-content wedding-content">
                                                <div class="count">
                                                    <div class="counter-container">
                                                        <h2 class="text-white text-right">
                                                            <span id="days" class="days-text"></span><span
                                                                class="days gradient-text">days</span>
                                                        </h2>
                                                    </div>
                                                    <div class="set-border-left"></div>
                                                    <div class="set-border-bottom"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-6">
                                            <div class="event-content wedding-content">
                                                <div class="count">
                                                    <div class="counter-container">
                                                        <h2 class=" text-white text-right">
                                                            <span id="hours"
                                                                  class="days-text gradient-text"></span><span
                                                                class="days gradient-text">hours
                                            </span>
                                                        </h2>
                                                    </div>
                                                    <div class="set-border-left"></div>
                                                    <div class="set-border-bottom"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-6">
                                            <div class="event-content wedding-content">
                                                <div class="count">
                                                    <div class="counter-container">
                                                        <h2 class="text-white text-right">
                                                            <span id="minutes"
                                                                  class="days-text gradient-text"></span><span
                                                                class="days gradient-text"> minutes</span>
                                                        </h2>
                                                    </div>
                                                    <div class="set-border-left"></div>
                                                    <div class="set-border-bottom"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-6">
                                            <div class="event-content wedding-content">
                                                <div class="count">
                                                    <div class="counter-container">
                                                        <h2 class=" text-white text-right">
                                                            <span id="seconds"
                                                                  class="days-text gradient-text"></span><span
                                                                class="days gradient-text">seconds</span>
                                                        </h2>
                                                    </div>
                                                    <div class="set-border-left"></div>
                                                    <div class="set-border-bottom"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="text-white zexon"><i class="fa fa-map-marker m-r-10 text-white"
                                                                aria-hidden="true"></i> Zexson Pro, New York, NY
                                    254
                                </h4>
                                <h5 class="header-sub-text text-white"><i class="fa fa-calendar m-r-10"
                                                                          aria-hidden="true"></i>01-04
                                    March
                                    2018</h5>
                                <div class="text-center link-horizontal">
                                    <ul>
                                        <li>
                                            <a class=" btn btn-default back-white text-uppercase border-not" href="#">view
                                                event
                                                details</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="set-bottom set-abs">
            <a class="center-content down" (click)="onRedirect($event)">
                <img src="assets/images/event/image-down.png" alt="" class="scroll-down">
            </a>
        </div>
    </div>
</section>
<!-- header end-->
