<a  class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide" >
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu" >
        <!-- 1st Level Menu -->
        <li>
            <!-- Sub -->
            <a href="https://facebook.com/101043908249189/" class="copyright-text"><i class="fa fa-facebook" aria-hidden="true"></i></a>
            <a href="https://instagram.com/arianna_monique/" class="copyright-text"><i class="fa fa-instagram" aria-hidden="true"></i></a>
            <a href="https://youtube.com/channel/UCoQribXfGQ0g2WC16iSqDDA/" class="copyright-text"><i class="fa fa-youtube" aria-hidden="true"></i></a>
            <a href="https://www.linkedin.com/in/arianna-castillo-mba-b84136b7/" class="copyright-text"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            <a href="https://www.tiktok.com/@ari_monique/"><img style="color:black; width: 14px" src="assets/images/resume/tiktok.svg"></a>
        </li>
        </ul>  
</div>
  
