<!--schedule start-->
<section class="resume schedule">
  <div class="container">
      <div class="title title2">
          <div class="row">
              <div class=" offset-md-2 col-md-8">
                  <h6 class="font-primary borders main-text text-uppercase"><span><h6 style="color:#DAB0B0">resume</h6></span></h6>
                  <div class="sub-title">
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <div class="cal-time">
                  <div class="time">
                      <h2 class="text-capitalize">Resume Details</h2>
                  </div>
              </div>
              <div class="event-container scrollbar">
                <div>
                    <div class=" cal-time events" >
                        <div class="row">
                            <div class="col-md-10">
                                <div class="schedule-event">
                                    <h4 class="names">DEGREES & CERTIFICATIONS</h4>
                                    <img  alt="" class="img-fluid schedule-img">
                                    <p class="schedule-description">•	DIGITAL MARKETING CERTIFICATION - UNIVERSITY OF TEXAS AT AUSTIN<br><br>
                                        •	MASTER OF BUSINESS ADMINISTRATION - UNIVERSITY OF TEXAS OF THE PERMIAN BASIN<br><br>
                                        •	BACHELOR IN ACCOUNTANCY - UNIVERSITY OF TEXAS OF THE PERMIAN BASIN<br><br>
                                        •	Google Ads Search Certification<br><br>
                                        •	Google Ads Display Certification<br><br>
                                        •	Google Ads - Measurement Certification<br><br>
                                        •	Google Ads Video Certification<br><br>
                                        •	Google Shopping ads Certification<br><br>
                                        •	Google Ads Apps Certification<br><br>
                                        •	Google Ads Creative Certification<br><br>

                                      </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                  <div>
                      <div class=" cal-time events" >
                          <div class="row">
                              <div class="col-md-10">
                                  <div class="schedule-event">
                                      <h4 class="names">BUSINESS RISK CONTROLS ADVISOR II, USAA</h4>
                                      <img  alt="" class="img-fluid schedule-img">
                                      <p class="schedule-description">•	Identified and managed existing and emerging risks that stem from business activities and the job role. Ensures risks associated with business activities are effectively identified, measured, monitored, and controlled. <br><br>
                                        •	Improved key performance indicators (KPIs) for Chief Financial Office Business Control Management team, by performing ongoing supervision and oversight of business controls which may include the coordination of testing, root cause analysis, control design, and examining key risk metrics to evaluate the effectiveness of established business controls by the established deadlines. <br><br>
                                        •	Advised the business control owners on how to strengthen and manage their control environment pertaining to oversight of procedures and accurate regulatory reporting and filings. <br><br>
                                        •	Assisted in the transition to a new control software from MetricStream to SNOW. Attended the introductory trainings to become a subject matter expert (SME) and trained staff on how to use the new software. <br><br>
                                        </p>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="timing">
                                      <h5 class="event-time">Present</h5>
                                      <div class="border-container">
                                          <div class="border-dashed"></div>
                                      </div>
                                      <h5 class="event-time">09.2020</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <br>
                  <div>
                    <div class=" cal-time events" >
                        <div class="row">
                            <div class="col-md-10">
                                <div class="schedule-event">
                                    <h4 class="names">AUDIT SENIOR ASSOCIATE I, WEAVER</h4>
                                    <img  alt="" class="img-fluid schedule-img">
                                    <p class="schedule-description">•	Managed client relationships and lead the day-to-day activities of audit and planned audit team tasks for the audit. Validated the progress of workpapers daily to ensure the time budget was adhered to. <br><br>
                                        •	Prepared and drafted audit reports for multiple audit engagements. Any issues were researched, and relevant information was provided to the management.<br><br>
                                        </p>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="timing">
                                    <h5 class="event-time">09.2020</h5>
                                    <div class="border-container">
                                        <div class="border-dashed"></div>
                                    </div>
                                    <h5 class="event-time">08.2020</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div>
                  <div class=" cal-time events" >
                      <div class="row">
                          <div class="col-md-10">
                              <div class="schedule-event">
                                  <h4 class="names">AUDIT ASSOCIATE II, WEAVER</h4>
                                  <img  alt="" class="img-fluid schedule-img">
                                  <p class="schedule-description">•	Managed client relationships. Types of clients included a $217 million dollar Securities and Exchange Commission (SEC) registrant, a $16 million dollar company publicly traded on the London Stock Exchange.<br><br>
                                    •	Lead the day-to-day activities of audit engagements which included employee benefit plans, preparing, and drafting audit reports. <br><br>
                                    •	Attended recruiting events to educate and influence students on why they should join the audit firm.<br><br>
                                    </p>
                              </div>
                          </div>
                          <div class="col-md-2">
                              <div class="timing">
                                  <h5 class="event-time">08.2020</h5>
                                  <div class="border-container">
                                      <div class="border-dashed"></div>
                                  </div>
                                  <h5 class="event-time">08.2019</h5>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <br>
              <div>
                <div class=" cal-time events" >
                    <div class="row">
                        <div class="col-md-10">
                            <div class="schedule-event">
                                <h4 class="names">AUDIT ASSOCIATE I, WEAVER</h4>
                                <img  alt="" class="img-fluid schedule-img">
                                <p class="schedule-description">•	Collaborated with audit team to accomplish day-to-day activities. Worked on multiple audit engagements simultaneously and demonstrated a basic understanding of the client’s operations and accounting system. Any issues were researched, and relevant information was provided to management.<br><br>
                                    •	Monitored own Work in Progress and ensures a positive contribution is made to departmental billing to ensure the audit budget is adhered to.<br><br>
                                    •	Communicated with clients to ensure requested documentation was received timely. Displayed interpersonal, planning, and organizational skills while using lateral and analytical thinking coupled to solve problems.<br><br>
                                    </p>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="timing">
                                <h5 class="event-time">07.2019</h5>
                                <div class="border-container">
                                    <div class="border-dashed"></div>
                                </div>
                                <h5 class="event-time">08.2018</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div>
              <div class=" cal-time events" >
                  <div class="row">
                      <div class="col-md-10">
                          <div class="schedule-event">
                              <h4 class="names">STAFF ACCOUNTANT, PREVENTATIVE CARE HEALTH SERVICES</h4>
                              <img  alt="" class="img-fluid schedule-img">
                              <p class="schedule-description">•	Established and managed social media presence on Twitter, Facebook, Instagram and LinkedIn. Designed promotional merchandise to assist with the marketing campaigns of the clinics while staying within the marketing budget.<br><br>
                                •	Conducted A/B testing on email campaigns, landing pages, and website content based on user behavior analysis to direct traffic to three clinic locations.<br><br>
                                •	Assisted CFO in preparing accurate financial statements, implementing controls, and preparing support for external auditors. Performed monthly bank reconciliations and bi-weekly payroll for the three clinics and administration office.<br><br>
                                •	Developed training materials for accounting processes and provided mentoring for staff.<br><br>                                
                                  </p>
                          </div>
                      </div>
                      <div class="col-md-2">
                          <div class="timing">
                              <h5 class="event-time">08.2018</h5>
                              <div class="border-container">
                                  <div class="border-dashed"></div>
                              </div>
                              <h5 class="event-time">08.2017</h5>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--schedule end-->
