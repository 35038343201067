<!--subscribe start-->
<section class="resume subscribe" id="contact">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 offset-lg-2">
              <div class="title title2">
                  <h1 class="subscribe-head" style="font-size: 60px;">Let's Connect</h1>
                  <p class="subscribe-sub-head font-primary"></p>
              </div>
          </div>
          <div class="col-lg-7">
              <div>
                  <div class="center-content">
                      <div>
                          <div>
                              <div class="button-primary">
                                  <a href="https://www.linkedin.com/in/arianna-castillo-mba-b84136b7/"><button type="submit" class=" btn btn-default text-white primary-btn"><h4 style="color:#DAB0B0">Connect</h4></button></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--subscribe end-->
