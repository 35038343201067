import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creative-two',
  templateUrl: './creative-two.component.html',
  styleUrls: ['./creative-two.component.scss']
})
export class CreativeTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
