<!--header start-->
<section class="resume header" id="home">
  <div class="header7-content">
      <div class="resume bg bg-pink"  [ngStyle]="{'background-image': 'url(assets/images/resume/h7-bg-text.png)'}">
          <div class="container">
              <div class="row">
                  <div class="col-lg-5 col-md-6">
                      <img src="assets/images/resume/h7.png" alt="" class="img-fluid set-abs bottom-0 right-0">
                  </div>
                  <div class="col-lg-6 col-md-6 offset-lg-1">
                      <div class="center-text">
                          <div>
                              <div class="header-sub-text">
                                  <h5 class="text-hash text-uppercase" style="color:#DAB0B0"># resume</h5>
                              </div>
                              <div class="header-text">
                                  <h1><span class="bold-text">Hi, I’m</span><br> Arianna Monique</h1>
                              </div>
                              <div class="header-sub-text">
                                  <p class="font-secondary font-large">Enthusiastic marketing professional pursuing a certificate in Digital Marketing from the University of Texas at Austin that finishes December 2022. 
                                    Proven accounting professional with years of progressive leadership growth within diverse business situations and result oriented 
                                    team leader with ability to create business opportunities with strategic thinking, data analytics, digital and social media marketing.</p>
                              </div>
                              <div class="link-horizontal">
                                  <ul>
                                      <li><a class="btn btn-default primary-btn back-white" href="https://docs.google.com/document/d/1cexdcTsWZL38uZ8I1Ah9XZcEZU591BQl/edit?usp=sharing&ouid=116115259980928801882&rtpof=true&sd=true" style="color:#DAB0B0"><h4 style="color:#DAB0B0">Download Resume</h4></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--header end-->