<!-- Nav Start-->
<header class="app2 loding-header nav-abs custom-scroll">
    <div class="animated-bg"><i></i><i></i><i></i></div>
  <div class="container">
      <div class="row">
          <div class="col">
            <nav>
              <a href="#" class="d-inline-block m-r-auto">
                  <img src="assets/images/logo/1.png" alt="" class="img-fluid">
              </a>
            <app-menu></app-menu>
          </nav>
          </div>
      </div>
  </div>
</header>
<!-- Nav end-->