import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-schedule',
  templateUrl: './resume-schedule.component.html',
  styleUrls: ['./resume-schedule.component.scss']
})
export class ResumeScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
schedule = [
  {
    name:'DEGREES',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'•	Member, Beta Gamma Sigma Honor Society',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'BUSINESS RISK CONTROLS ADVISOR II, USAA',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'AUDIT SENIOR ASSOCIATE I, WEAVER',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'AUDIT ASSOCIATE II, WEAVER',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'AUDIT ASSOCIATE I, WEAVER',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'STAFF ACCOUNTANT, PREVENTATIVE CARE HEALTH SERVICES',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'STAFF ACCOUNTANT, PREVENTATIVE CARE HEALTH SERVICES',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  }
]
}
