<app-content></app-content>
<!--contact section start-->
<footer class="resume copyright copyright-bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="link link-horizontal">
                        <ul class="justify-content-center">
                            <li>
                                <a href="#" class="copyright-text">home</a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">couple</a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">invitation</a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">album </a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">friends say</a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">gift</a>
                            </li>
                        </ul>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <div class="text-center">
                        <div class="social-link link-horizontal">
                            <ul class="justify-content-center">
                                <li>
                                    <a href="#" class="copyright-text">
                                        <i class="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="copyright-text">
                                        <i class="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="copyright-text">
                                        <i class="fa fa-google" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="copyright-text ">
                                        <i class="fa fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="m-l-auto center-para">
                        <h6 class="copyright-text text-center">© Created by Arianna Monique</h6>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--contact section end-->
    