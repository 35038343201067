import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-two-grid',
  templateUrl: './basic-two-grid.component.html',
  styleUrls: ['./basic-two-grid.component.scss']
})
export class BasicTwoGridComponent implements OnInit {
  
  constructor() {
  }

  ngOnInit() {
  }
  
}
