<!-- section start -->
<section class="portfolio-creative portfolio-section  creative2 creative3 p-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-12 p-0 isotopeSelector">
                        <img src="assets/images/portfolio/1.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-12">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum is simply dummy text
                                </h5>
                            </div>
                            <div class="text-right">
                                <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 isotopeSelector">
                        <img src="assets/images/portfolio/2.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-12">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum is simply dummy text
                                </h5>
                            </div>
                            <div class="text-right">
                                <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-12 p-0 isotopeSelector order-lg-2">
                        <img src="assets/images/portfolio/3.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-12">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum is simply dummy text
                                </h5>
                            </div>
                            <div class="text-right">
                                <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                            </div>
                        </div>
                    </div>
                    </div>
                <div class="row">
                    <div class="col-12 p-0 isotopeSelector order-lg-2">
                        <img src="assets/images/portfolio/4.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-12">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum is simply dummy text
                                </h5>
                            </div>
                            <div class="text-right">
                                <a href="javascript:void(0)" class="btn btn-default primary-btn radius-0">view more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->
