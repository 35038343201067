<!-- Navbar section Start-->
<header class="saas1 header-fixed loding-header position-absolute  custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/3.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navbar section end-->

<!-- header section Start-->
<section class="saas1 header" id="homer">
    <div class="saas1-header bg header8-content" [ngStyle]="{'background-image': 'url(assets/images/saas1/slider-banner.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="center-text justify-content-center text-center">
                        <div>
                            <div class="header-text">
                                <div class="d-flex">
                                    <h1><span class="theme-color">S</span>imple <span class="small-text">and </span><span class="theme-color">S</span>mart</h1>
                                    <div class="center-content slider-logo">
                                        <img src="assets/images/saas1/slider-logo.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="header-sub-text">
                                <h3 class="text-white">Landing Page Templates For<span> SaaS</span> Companies</h3>
                            </div>
                            <div class="header-sub-text">
                                <h6>For as low as<span class="theme-color"> $0.95 </span>per user account</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/saas1/background2.png" class="img-fluid set-abs background" alt="">
    <img src="assets/images/saas1/dot.png" class="img-fluid set-abs dot" alt="">
</section>
<!-- header section end-->
