import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creative-four',
  templateUrl: './creative-four.component.html',
  styleUrls: ['./creative-four.component.scss']
})
export class CreativeFourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
