import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-width-three-grid',
  templateUrl: './full-width-three-grid.component.html',
  styleUrls: ['./full-width-three-grid.component.scss']
})
export class FullWidthThreeGridComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
