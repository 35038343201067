    <!-- section start -->
    <div class="agency">
    <section>
        <div class="collection-wrapper">
            <div class="container">
                <div class="row data-sticky_parent">
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <div class="col-12 product_img_scroll image-scroll" data-sticky_column>
                                            <div>
                                                <div><img alt="" class="img-fluid" src="assets/images/portfolio/1.jpg">
                                                </div>
                                                <div><img alt="" class="img-fluid" src="assets/images/portfolio/2.jpg">
                                                </div>
                                                <div><img alt="" class="img-fluid" src="assets/images/portfolio/3.jpg">
                                                </div>
                                                <div><img alt="" class="img-fluid" src="assets/images/portfolio/4.jpg">
                                                </div>
                                                <div><img alt="" class="img-fluid" src="assets/images/portfolio/5.jpg">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 rtl-text">
                                    <div class="product-right pro_sticky_info" data-sticky_column>
                                        <div class="portfolio-detail m-b-20">
                                            <h3 class="detail-head">project detail</h3>
                                            <div class="detail-container d-flex p-t-0">
                                                <div class="portfolio-left">
                                                    <h5 class="text-left">client :</h5>
                                                </div>
                                                <div class="portfolio-right">
                                                    <h5>john doe</h5>
                                                </div>
                                            </div>
                                            <div class="detail-container d-flex">
                                                <div class="portfolio-left">
                                                    <h5 class="text-left">date :</h5>
                                                </div>
                                                <div class="portfolio-right">
                                                    <h5>22 Nov 2019</h5>
                                                </div>
                                            </div>
                                            <div class="detail-container d-flex">
                                                <div class="portfolio-left">
                                                    <h5 class="text-left">link :</h5>
                                                </div>
                                                <div class="portfolio-right">
                                                    <h5>www.abcd.com</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="portfolio-detail">
                                            <h3 class="detail-head">about project</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been
                                                the industry's standard dummy text ever since the 1500s, when an unknown
                                                printer took a galley
                                                of type and scrambled it to make a type specimen book.</p>
                                            <div class="text-right m-t-10"><a class="btn btn-default primary-btn radius-0"
                                                                              href="#">visit
                                                project</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
    <!-- Section ends -->
