import { Component, OnInit } from '@angular/core';
import { ColorScssService } from '../../../shared/service/color-scss.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
@Component({
  selector: 'app-portfolio-gallery',
  templateUrl: './portfolio-gallery.component.html',
  styleUrls: ['./portfolio-gallery.component.scss']
})

export class PortfolioGalleryComponent implements OnInit {
  public customizer: any = "all"
  items: GalleryItem[];
 

  constructor(private displayblock: ColorScssService, public gallery: Gallery, public lightbox: Lightbox) {
  }

  imageData = [
    {
      srcUrl: 'assets/images/portfolio/1.jpg',
      linkUrl: 'https://www.tiktok.com/t/ZTRxkRNyt/'
    },
    {
      srcUrl: 'assets/images/portfolio/2.jpg',
      linkUrl: 'https://www.tiktok.com/t/ZTRxkDwRA/'
    },
    {
      srcUrl: 'assets/images/portfolio/3.jpg',
      linkUrl: 'https://www.tiktok.com/t/ZTRxkUoLf/'
    },
    {
      srcUrl: 'assets/images/portfolio/4.jpg',
      linkUrl: 'https://m.facebook.com/story.php?story_fbid=136139079160876&id=100082943545416'
    },
    {
      srcUrl: 'assets/images/portfolio/5.jpg',
      linkUrl: 'https://www.instagram.com/reel/ChGgKZ5L0m6/?igshid=YmMyMTA2M2Y='
    },
    {
      srcUrl: 'assets/images/portfolio/6.jpg',
      linkUrl: 'https://m.facebook.com/story.php?story_fbid=136139079160876&id=100082943545416'
    },
    {
      srcUrl: 'assets/images/portfolio/7.jpg',
      linkUrl: 'https://youtu.be/-4qvGSMyT7k'
    },
    {
      srcUrl: 'assets/images/portfolio/9.jpg',
      linkUrl: 'https://youtu.be/-KGu58YkydY'
    }
  ]

  ngOnInit() {
    this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.srcUrl }));
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });
    lightboxRef.load(this.items);
  }

  openGallery(val) {
    this.customizer = val
  }

}
