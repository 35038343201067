import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-services',
  templateUrl: './resume-services.component.html',
  styleUrls: ['./resume-services.component.scss']
})
export class ResumeServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  service = [
    {
      img:"assets/images/resume/service-exprience/8.png",
      type:'SEO'
    },
    {
      img:"assets/images/resume/service-exprience/4.png",
      type:'Website Design'
    },
    {
      img:"assets/images/resume/service-exprience/7.png",
      type:'Community Management'
    },
    {
      img:"assets/images/resume/service-exprience/6.png",
      type:'Data Analytics'
    },
    {
      img:"assets/images/resume/service-exprience/3.png",
      type:'Google Ads'
    },
    {
      img:"assets/images/resume/service-exprience/1.png",
      type:'Content Creation'
    },
    {
      img:"assets/images/resume/service-exprience/5.png",
      type:'Marketing Automation'
    },
    {
      img:"assets/images/resume/service-exprience/2.png",
      type:'Adobe Suite'
    }
   ]


}
