<app-modern-nav></app-modern-nav>
<app-modern-header></app-modern-header>
<app-modern-about></app-modern-about>
<app-modern-services></app-modern-services>
<app-modern-function></app-modern-function>
<app-modern-team></app-modern-team>
<app-modern-screenshots></app-modern-screenshots>
<app-modern-team-member></app-modern-team-member>
<app-modern-pricing></app-modern-pricing>
<app-modern-brand></app-modern-brand>
<app-modern-subscribe></app-modern-subscribe>
<app-modern-footer></app-modern-footer>