<div class="row">
    <div class="col-12">
        <div class="filter-container isotopeFilters">
            <ul class="list-inline filter">
                <li [ngClass]="{'active': customizer == 'all' }"><a (click)="openGallery('all')">All </a></li>
                <li [ngClass]="{'active': customizer == 'fashion' }"><a (click)="openGallery('fashion')">YouTube</a>
                </li>
                <li [ngClass]="{'active': customizer == 'bags' }"><a (click)="openGallery('bags')">TikTok</a></li>
                <li [ngClass]="{'active': customizer == 'shoes' }"><a (click)="openGallery('shoes')">Facebook</a></li>
                <li [ngClass]="{'active': customizer == 'watch' }"><a (click)="openGallery('watch')">Instagram</a></li>
            </ul>
        </div>
    </div>
</div>
<div class="isotopeContainer row">
    <ng-container *ngIf="customizer=='all'" >
        <figure class="p-width" itemprop="associatedMedia" *ngFor="let img of imageData">
            <a [href]="img.linkUrl" itemprop="contentUrl">
              <img [src]="img.srcUrl" class="img-fluid" />
            </a>
          </figure>
    </ng-container>
    <ng-container *ngIf="customizer=='fashion'">
        <app-fashion class="w-100"></app-fashion>
    </ng-container>
    <ng-container *ngIf="customizer=='bags'">
        <app-bags class="w-100"></app-bags>
    </ng-container>
    <ng-container *ngIf="customizer=='shoes'">
        <app-shoes class="w-100"></app-shoes>
    </ng-container>
    <ng-container *ngIf="customizer=='watch'">
        <app-watch class="w-100"></app-watch>
    </ng-container>
</div>