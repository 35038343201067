<!--header start-->
<section class="app2 header overflow-unset" id="homer">
  <div class="app2-header bg">
      <div class="container">
          <div class="row">
              <div class="col-xl-7 col-md-6">
                  <div class="center-text">
                      <div>
                          <h6 class="header-top-line"># app landing page</h6>
                          <div class="header-text">
                              <h1>the best <span class="bold-text">landing page</span> for your app</h1>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white">Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                  ever
                                  since the 1500s.</p>
                          </div>
                          <div class="link-horizontal">
                              <ul>
                                  <li>
                                      <a class="btn btn-default btn-white">get app
                                          now</a>
                                  </li>
                                  <li>
                                      <a class="btn btn-default primary-btn transparent">discover
                                          more</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-5 col-md-6">
                  <div class="img-mobile set-abs">
                      <img src="assets/images/app_landing2/h2-mobile.png" alt="" class="headaer-image">

                  </div>
                  <div class="wave-orange"></div>
              </div>
          </div>
      </div>
      <div class="wave"></div>
  </div>
</section>
<!--header end-->