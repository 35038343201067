<section class="portfolio-creative portfolio-section  creative2 creative3 p-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-12 p-0 isotopeSelector">
                        <img alt="" class="img-fluid" src="assets/images/portfolio/1.jpg">
                    </div>
                    <div class="col-12 p-0">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum
                                </h5>
                            </div>
                            <div class="text-right">
                                <a class="btn btn-default primary-btn bor" href="#">view more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 isotopeSelector">
                        <img alt="" class="img-fluid" src="assets/images/portfolio/2.jpg">
                    </div>
                    <div class="col-12 p-0 ">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum
                                </h5>
                            </div>
                            <div class="text-right">
                                <a class="btn btn-default primary-btn bor" href="#">view more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-12 set-order p-0">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum
                                </h5>
                            </div>
                            <div class="text-right">
                                <a class="btn btn-default primary-btn bor" href="#">view more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 isotopeSelector">
                        <img alt="" class="img-fluid" src="assets/images/portfolio/3.jpg">
                    </div>
                    <div class="col-12 p-0">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum
                                </h5>
                            </div>
                            <div class="text-right">
                                <a class="btn btn-default primary-btn bor" href="#">view more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 isotopeSelector">
                        <img alt="" class="img-fluid" src="assets/images/portfolio/4.jpg">
                    </div>

                </div>
            </div>
            <div class="col-lg-4">
                <div class="row">
                    <div class="col-12 p-0 isotopeSelector">
                        <img alt="" class="img-fluid" src="assets/images/portfolio/1.jpg">
                    </div>
                    <div class="col-12 p-0">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum
                                </h5>
                            </div>
                            <div class="text-right">
                                <a class="btn btn-default primary-btn bor" href="#">view more</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 isotopeSelector">
                        <img alt="" class="img-fluid" src="assets/images/portfolio/2.jpg">
                    </div>
                    <div class="col-12 p-0">
                        <div class="portfolio-text d-flex">
                            <div class="w-75">
                                <h2 class="head-text">
                                    Lorem Ipsum
                                </h2>
                                <h5 class="head-sub-text">
                                    Lorem Ipsum
                                </h5>
                            </div>
                            <div class="text-right">
                                <a class="btn btn-default primary-btn bor" href="#">view more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>